import { ApplicationError, IApplicationError } from '@norstella/nxp-sso-core/errors/ApplicationError'

export class UpdateProfileError<TErrorData> extends ApplicationError<TErrorData> {
  constructor(errorDetails?: Partial<IApplicationError<TErrorData>>) {
    super({
      displayName: 'System error',
      message: 'Profile data could not be updated. Try again later.',
      ...errorDetails,
    })
  }
}
