import React, { ChangeEvent, FC, memo } from 'react'
import { InputLabel, OutlinedInput } from '@mui/material'

import styles from './Input.module.scss'

export interface IPhoneNumberInputProps {
  id: string,
  value: string,
  disabled?: boolean,
  displayName?: string,
  required?: boolean,
  handleEdit?: (id: string, value: string) => void,
}

const PHONE_PREFIX = '+'
const DIGIT_PART_LENGTH_MAX = 15
const MAX_PHONE_NUMBER_LENGTH = PHONE_PREFIX.length + DIGIT_PART_LENGTH_MAX
const PHONE_FORMAT_REGEX = /^[0-9]{0,15}$/
const EMPTY_VALUE = ''

export const PhoneNumberInput: FC<IPhoneNumberInputProps> = memo(({
  id,
  displayName = '',
  required = false,
  disabled = false,
  value,
  handleEdit,
}) => {
  let currentValue = value ?? EMPTY_VALUE

  if (currentValue !== EMPTY_VALUE && !currentValue.startsWith(PHONE_PREFIX)) {
    currentValue = `${PHONE_PREFIX}${currentValue}`
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.persist()
    let newRawValue = event.target.value || EMPTY_VALUE

    if (newRawValue === EMPTY_VALUE) {
      handleEdit?.(event.target.id, EMPTY_VALUE)

      return
    }

    if (!newRawValue.startsWith(PHONE_PREFIX)) {
      newRawValue = `${PHONE_PREFIX}${newRawValue}`
    }

    const newValue = newRawValue.slice(PHONE_PREFIX.length)

    if (PHONE_FORMAT_REGEX.test(newValue)) {
      handleEdit?.(event.target.id, newValue)
    }
  }

  return <div className={styles.phoneNumberInput}>
    <InputLabel htmlFor={id} className={styles.inputLabel} disabled={disabled} id={`${id}-label`}>
      {`${required ? '*' : ''}${displayName}`}
    </InputLabel>
    <OutlinedInput
      className={styles.input}
      id={id}
      disabled={disabled}
      name={id}
      fullWidth={true}
      onChange={onChange}
      value={currentValue}
      inputProps={{
        maxLength: MAX_PHONE_NUMBER_LENGTH,
      }}
    />
  </div>
})
