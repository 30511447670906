import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { ApplicationRoutes, getConfiguredRoutes } from '@norstella/nxp-sso-web/services/RoutesService'

export const useApplicationRoutes = (): ApplicationRoutes => {
  const { search } = useLocation()
  const { registrationStatus } = useAppContext()

  return useMemo(
    () => getConfiguredRoutes(registrationStatus, search),
    [registrationStatus, search],
  )
}
