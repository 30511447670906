import * as React from 'react'
import { useLocationSource } from '@norstella/nxp-sso-web/hooks/useLocationSource'

export const useNavigateToSource = (): ((event: React.SyntheticEvent) => void) | undefined => {
  const source = useLocationSource()

  return source ?
    (event: React.SyntheticEvent) => {
      event.preventDefault()
      source && window.location.replace(source)
    } :
    undefined
}
