import { useMemo } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { User } from '@norstella/nxp-sso-web/utils/User'
import { IRegistrationStatus } from '@norstella/nxp-sso-web/services/RoutesService'
import { useSignupRedirectState } from '@norstella/nxp-sso-web/hooks/useSignupRedirectState'
import { useNewEntitlementProviderIds } from '@norstella/nxp-sso-web/hooks/useNewEntitlementProviderIds'
import { useRegistrationMode } from '@norstella/nxp-sso-web/hooks/useRegistrationMode'
import { RegistrationMode } from '@norstella/nxp-sso-core/interfaces/sso-api'

export const useRegistrationStatus = (shouldContinueLogin = false): IRegistrationStatus => {
  const { user, isAuthenticated } = useAuth0<User>()
  const [signupRedirectState] = useSignupRedirectState()
  const newEntitlementProviderIds = useNewEntitlementProviderIds()
  const registrationMode = useRegistrationMode()

  return useMemo(() => {
    const needEmailVerification = isAuthenticated ? !user?.email_verified : true
    const isUpgradingUser = !!(user?.registration_completed && (registrationMode === RegistrationMode.REGULAR) && user?.user_metadata?.first_name === undefined)

    const needSignupCompletion = isAuthenticated
      ? (!needEmailVerification && (!user?.registration_completed || newEntitlementProviderIds.length > 0 || isUpgradingUser)) || shouldContinueLogin
      : true

    const isRegistrationCompleted = !signupRedirectState && !needSignupCompletion && !needEmailVerification

    return {
      needEmailVerification,
      needSignupCompletion,
      isRegistrationCompleted,
      registrationMode,
    } as IRegistrationStatus
  },
  [
    isAuthenticated,
    shouldContinueLogin,
    signupRedirectState,
    user?.email_verified,
    user?.registration_completed,
    user?.user_metadata.first_name,
    newEntitlementProviderIds,
    registrationMode,
  ])
}
