import styles from '@norstella/nxp-sso-web/components/TrialUserHeader.module.scss'

export const TrialUserHeader = () => {
  return <>
    <div className={styles.subHeader}>
      <h1>Start your 7-day FREE trial to&nbsp;Citeline News and Insights today!</h1>
      <p>Your trial covers one of&nbsp;the following:</p>
      <ul>
        <li>Pink Sheet</li>
        <li>Scrip</li>
        <li>Medtech Insight</li>
        <li>In Vivo</li>
        <li>Generics Bulletin</li>
        <li>HBW Insight</li>
      </ul>
      <hr className={styles.headerSeparationLine}/>
    </div>
  </>
}

