import { useEffect, useRef } from 'react'
import { useSignupRedirectState } from '@norstella/nxp-sso-web/hooks/useSignupRedirectState'
import { getAuth0Config } from '@norstella/nxp-sso-web/config'
import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard'
import { FormType } from '@norstella/nxp-sso-web/utils/Forms'
import { LoadingIndicator } from '@norstella/nxp-sso-web/components/LoadingIndicator'
import { useBranding } from '../hooks/useBranding'

export const ContinueLogin = () => {
  const auth0Config = getAuth0Config()
  const formRef = useRef<HTMLFormElement>(null)
  const [signupState, resetSignupState] = useSignupRedirectState()
  const [forms] = useBranding()
  const { header, description } = forms[FormType.continueLogin]

  useEffect(() => {
    if (formRef.current && signupState) {
      resetSignupState()
      formRef.current.requestSubmit()
    }
  }, [formRef, resetSignupState, signupState])

  return <ApplicationCard header={header} description={description}>
    <LoadingIndicator />
    <form
      aria-label='continueLogin'
      method='POST'
      action={`https://${auth0Config.domain}/continue?state=${signupState?.state || ''}`}
      ref={formRef}>
      {
        Object
          .entries(signupState?.data || {})
          .map(([name, value]) => <input key={name} type='hidden' name={name} value={value} />)
      }
    </form>
  </ApplicationCard>
}
