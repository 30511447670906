import { UserType } from '@norstella/nxp-sso-core/interfaces/sso-api'
import countries from '@norstella/nxp-sso-core/lists/countries'
import { FormFieldType, IProfileFormField } from '@norstella/nxp-sso-web/components/userProfile/formFieldFactory'
import { FormType } from '@norstella/nxp-sso-web/utils/Forms'
import { pipe, curry } from 'lodash/fp'

export const profileFormFields: IProfileFormField[] = [
  {
    id: 'email',
    role: 'textbox',
    displayName: 'Email address',
    required: true,
    disabled: true,
    visibleOn: [FormType.profile, FormType.trialProfile],
    type: FormFieldType.text,
  },
  {
    id: 'first_name',
    role: 'textbox',
    displayName: 'First name',
    required: true,
    type: FormFieldType.text,
  },
  {
    id: 'last_name',
    role: 'textbox',
    displayName: 'Last name',
    required: true,
    type: FormFieldType.text,
  },
  {
    id: 'job_title',
    role: 'textbox',
    displayName: 'Job title',
    required: true,
    type: FormFieldType.text,
  },
  {
    id: 'company',
    role: 'textbox',
    displayName: 'Company',
    required: true,
    type: FormFieldType.text,
  },
  {
    id: 'country_region',
    role: 'select',
    displayName: 'Country/Region',
    required: true,
    values: countries,
    type: FormFieldType.select,
  },
  {
    id: 'phone_no',
    role: 'textbox',
    displayName: 'Phone Number',
    required: false,
    requiredOn: [FormType.trialSignup, FormType.trialProfile],
    type: FormFieldType.phone,
  },
]

const federatedUserDisabledFields = ['email', 'first_name', 'last_name', 'job_title', 'company', 'country_region', 'phone_no']

const visibleFieldMapper = curry((userType: UserType, formType: FormType, field: IProfileFormField) => {
  const isVisible = field.visibleOn === undefined || field.visibleOn.includes(formType)
  return isVisible ?
    { ...field } :
    null
})

const requiredFieldMapper = curry((userType: UserType, formType: FormType, field: IProfileFormField) => ({
  ...field,
  required: (field.requiredOn || []).includes(formType) || !!field.required,
}))

const federatedUserDisabledFieldsMapper = curry((userType: UserType, formType: FormType, field: IProfileFormField) => ({
  ...field,
  disabled: userType === UserType.federated ?
    federatedUserDisabledFields.includes(field.id) :
    !!field.disabled,
}))

const formFieldsMapper = (userType: UserType, formType: FormType) => pipe(
  requiredFieldMapper(userType, formType),
  federatedUserDisabledFieldsMapper(userType, formType),
  visibleFieldMapper(userType, formType),
)

export const getFormProfileFields = (formType: FormType, userType: UserType = UserType.user) =>
  profileFormFields
    .map(formFieldsMapper(userType, formType))
    .filter(f => !!f) as IProfileFormField[]
