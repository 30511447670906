import { useMemo } from 'react'
import { RouteObject } from 'react-router-dom'

import { useApplicationRoutes } from '@norstella/nxp-sso-web/hooks/useApplicationRoutes'

export const useAvailableRoutes = (): RouteObject[] => {
  const allRoutes = useApplicationRoutes()

  return useMemo(
    () => Object.values(allRoutes).filter(route => route.isAvailable),
    [allRoutes],
  )
}
