import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect, useState } from 'react'
import jwt_decode from 'jwt-decode'
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'

export interface IAccessToken {
  app_group_id: string,
  uid: string,
  scope: string,
  entls: object,
  aud: string[],
  [key: string]: object | string,
}

export interface ITokens {
  idToken: string,
  accessToken: string | IAccessToken,
  expiresIn?: number,
}

export type GetTokenSilentlyVerboseOptions = GetTokenSilentlyOptions & { detailedResponse: true }

export type UseDecodedTokens = [
  tokens: ITokens,
  reloadTokens: (options: GetTokenSilentlyVerboseOptions) => Promise<void>,
  isLoading: boolean,
]

const defaultTokens: ITokens = {
  idToken: '',
  accessToken: '',
  expiresIn: undefined,
}

export const useDecodedTokens = (): UseDecodedTokens => {
  const [tokens, setTokens] = useState<ITokens>(defaultTokens)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated } = useAuth0()
  const { setMessage } = useAppContext()
  const reloadTokens = useCallback(async (options?: GetTokenSilentlyVerboseOptions) => {
    const currentOptions = options || { detailedResponse: true } as GetTokenSilentlyVerboseOptions
    if (isAuthenticated) {
      setIsLoading(true)
      let loadedTokens: GetTokenSilentlyVerboseResponse | undefined = {} as GetTokenSilentlyVerboseResponse
      try {
        // try to load access token silently
        loadedTokens = await getAccessTokenSilently(currentOptions)
      } catch (error) {
        try {
          // fallback to get access token with popup that allows to give user consent if required
          await getAccessTokenWithPopup(currentOptions)
        } catch (error) {
          // things can not be handled, show error message and reset tokens
          setMessage(error as Error)
          loadedTokens = undefined
          return
        }
        loadedTokens = await getAccessTokenSilently(currentOptions)
      } finally {
        const tokens: ITokens = loadedTokens ? {
          idToken: jwt_decode(loadedTokens.id_token),
          accessToken: jwt_decode(loadedTokens.access_token),
          expiresIn: loadedTokens.expires_in,
        } : defaultTokens

        setTokens(tokens)
        setIsLoading(false)
      }
    }
  }, [getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated, setMessage])

  useEffect(() => {
    reloadTokens().catch(setMessage)
    // NOTE: empty dependencies to reload tokens only initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [tokens, reloadTokens, isLoading]
}
