import React, { ReactNode } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { getAuth0Config } from '../config'

type State = {
  returnTo?: string,
}

export const Auth0ProviderWithNavigate = ({ children }: {children: ReactNode}) => {
  const navigate = useNavigate()
  const auth0Config = getAuth0Config()

  const onRedirectCallback = (appState?: State) => {
    const navigateTo = appState?.returnTo || window.location.pathname
    navigate(navigateTo)
  }

  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={auth0Config.authorizationParams}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  )
}
