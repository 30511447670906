import React, { FC, memo } from 'react'
import {
  InputLabel,
  MenuItem,
  Select as MuiSelect, SelectChangeEvent,
} from '@mui/material'
import { createSvgIcon } from '@mui/material/utils'
import styles from './Select.module.scss'

export interface ISelectProps {
  id: string,
  value: string,
  displayName?: string,
  required?: boolean,
  disabled?: boolean,
  values?: Array<string> | [],
  iconViewBox?: string,
  handleEdit?: (id: string, value: string) => void,
}

const SelectIcon = createSvgIcon(
  <path
        transform="translate(5, 6.915)"
        d="M8.825 0.912598L5 4.72926L1.175 0.912598L0 2.0876L5 7.0876L10 2.0876L8.825 0.912598Z"
        fill="#475467"
  />, 'test',
)

export const Select: FC<ISelectProps> = memo(({
  id,
  displayName = '',
  disabled = false,
  required = false,
  value,
  iconViewBox = '0 0 20 20',
  values,
  handleEdit,
}) => {
  const label = `${required ? '*' : ''}${displayName}`
  const onChange = (event: SelectChangeEvent<string>) => {
    event.preventDefault()
    const fieldName = event.target.name
    const fieldValue = event.target.value
    handleEdit?.(fieldName, fieldValue)
  }

  const renderOptions = () => values?.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)

  return <>
    <InputLabel disabled={disabled} className={styles.inputLabel} id={`${id}-label`}>{label}</InputLabel>
    <MuiSelect
      role='select'
      aria-label={label}
      IconComponent={({ ...props }) => <SelectIcon viewBox={iconViewBox} {...props} />}
      className={styles.input}
      disabled={disabled}
      data-testid={`input-${id}`}
      id={id}
      name={id}
      fullWidth={true}
      value={value}
      onChange={onChange}
    >
      {renderOptions()}
    </MuiSelect>
  </>
})

