import { useMemo } from 'react'
import { getFormProfileFields } from '@norstella/nxp-sso-web/utils/profileFormFields'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { FormType } from '@norstella/nxp-sso-web/utils/Forms'
import { ProfileDataItemKey, IProfileFormField } from '@norstella/nxp-sso-web/components/userProfile/formFieldFactory'

export interface IProfileFormFields {
  allFields: IProfileFormField[],
  requiredFields: {
    id: ProfileDataItemKey,
    displayName: string,
  }[],
}

export const useProfileFormFields = (formType: FormType): IProfileFormFields => {
  const { profileData } = useAppContext()
  return useMemo(() => {
    const fields = profileData.userType ?
      getFormProfileFields(formType, profileData.userType) :
      []
    const requiredFields = profileData.userType ?
      fields.filter(f => f.required).map(f => ({ id: f.id, displayName: f.displayName })) :
      []
    return {
      allFields: fields,
      requiredFields,
    } as IProfileFormFields
  }, [formType, profileData.userType])
}
