import { useEffect } from 'react'
import { useSignupRedirectState } from '@norstella/nxp-sso-web/hooks/useSignupRedirectState'
import { useRegistrationStatus } from '@norstella/nxp-sso-web/hooks/useRegistrationStatus'
import { useFeatureFlags } from '@norstella/nxp-sso-web/hooks/useFeatureFlags';

export const useRedirectStateCleanupEffect = (): void => {
  const { needEmailVerification, needSignupCompletion, isRegistrationCompleted } = useRegistrationStatus()
  const [, cleanupRedirectState] = useSignupRedirectState()
  const { isDebugLevelEnabled } = useFeatureFlags()

  useEffect(() => {
    if (
      !needEmailVerification
      && !needSignupCompletion
      && !isRegistrationCompleted
    ) {
      if (isDebugLevelEnabled) {
        console.log('cleanupRedirectState', JSON.stringify({
          needEmailVerification,
          needSignupCompletion,
          isRegistrationCompleted,
        }, null, 2))
      }

      cleanupRedirectState()
    }
  }, [cleanupRedirectState, isRegistrationCompleted, needEmailVerification, needSignupCompletion])
}
