import { FC, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TokenDetails from '@norstella/nxp-sso-web/components/TokenDetails'

import styles from '@norstella/nxp-sso-web/components/TokenTester.module.scss'

export const TokenTester: FC = () => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button variant="contained" onClick={handleClickOpen}>
        Token Tester
      </Button>
      <Dialog
        open={open}
        fullWidth
        maxWidth="lg"
        keepMounted={true}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>NXP SSO Token Tester</DialogTitle>
        <DialogContent>
          <TokenDetails/>
        </DialogContent>
        <DialogActions className={styles.dialogActions}>
          <Button variant="contained" onClick={handleClose}>close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
