import { FC, useCallback } from 'react'
import Highlight from 'react-highlight'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { useDecodedTokens } from '@norstella/nxp-sso-web/hooks/useDecodedTokens'
import { useForm } from '@norstella/nxp-sso-web/hooks/useForm'

import { Input } from '@norstella/nxp-sso-web/components/userProfile/Input'

import 'highlight.js/scss/solarized-dark.scss'
import styles from '@norstella/nxp-sso-web/components/TokenDetails.module.scss'

const TokenDetails: FC = () => {
  const [authorizationParams, handleFormEdit] = useForm({
    audience: 'https://norstella/sso/api',
    scope: 'openid profile email nxp-sso:user',
  })
  const [tokens, reloadTokens, isLoading] = useDecodedTokens()
  const onSubmitForm = useCallback(async () => {
    reloadTokens({
      cacheMode: 'off',
      detailedResponse: true,
      authorizationParams,
    })
  }, [authorizationParams, reloadTokens])

  return <div className={styles.tokensContainer}>
    <form id="tokenTesterForm" onSubmit={event => {
      event.preventDefault()
      event.stopPropagation()
      onSubmitForm()
    }}>
      <Input displayName="Audience" disabled={false} id="audience" value={authorizationParams.audience} handleEdit={handleFormEdit} />
      <Input displayName="Scope" id="scope" value={authorizationParams.scope} handleEdit={handleFormEdit}></Input>
      <div className={styles.reloadButtonContainer}>
        <Button type="submit" disabled={isLoading} form="tokenTesterForm" variant="contained">Reload</Button>
      </div>
    </form>

    <Accordion className={styles.tokenContainer}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>ID Token</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Highlight className={`json ${styles.profileContainer}`}>
          {JSON.stringify(tokens.idToken, null, 2)}
        </Highlight>
      </AccordionDetails>
    </Accordion>
    <Accordion className={styles.tokenContainer}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography>Access Token</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Highlight className="json">
          {JSON.stringify(tokens.accessToken, null, 2)}
        </Highlight>
      </AccordionDetails>
    </Accordion>
  </div>
}

export default TokenDetails
