import React, { FC, useCallback, useEffect } from 'react'
import { Typography, Button, Divider } from '@mui/material'

import { FormType } from '@norstella/nxp-sso-web/utils/Forms'

import { LoadingIndicator } from '@norstella/nxp-sso-web/components/LoadingIndicator'
import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard'

import { IProfileData } from '@norstella/nxp-sso-core/interfaces/sso-api'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'

import viewStyles from '@norstella/nxp-sso-web/components/views/FederatedSignupView.module.scss'
import { useBranding } from '@norstella/nxp-sso-web/hooks/useBranding'

export interface IFederatedSignupViewProps {
  onSubmitHandler: (profileData: IProfileData) => Promise<void>,
  error?: Error,
}

export const FederatedSignupView: FC<IFederatedSignupViewProps> = ({
  onSubmitHandler,
  error,
}) => {
  const [forms] = useBranding()
  const { header, description, footer } = forms[FormType.federatedSignup]
  const { profileData } = useAppContext()

  const submitCallback = useCallback(() => {
    (async () => await onSubmitHandler(profileData))()
  }, [profileData, onSubmitHandler])

  useEffect(() => {
    submitCallback()
  }, [submitCallback])

  return <ApplicationCard header={header} description={description}>
    {!error && <LoadingIndicator />}
    {
      error &&
      <>
        <Divider></Divider>
        <h2>Need help?</h2>
        <Typography component="p">
          {footer && footer.text}
          <a
            href={footer?.linkUrl}
            target="_blank"
            rel="noopener nofollow noreferrer"
          >
            {footer && footer.linkText}
          </a>
        </Typography>
        <div className={viewStyles.buttonSection}>
          <Button
            data-testid="update-button"
            type="submit"
            variant="contained"
            onClick={submitCallback}
            color="primary">
            try again
          </Button>
        </div>
      </>
    }
  </ApplicationCard>
}
