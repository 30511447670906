import { useCallback } from 'react'

import api from '@norstella/nxp-sso-web/api'
import { useApiCallback } from '@norstella/nxp-sso-web/hooks/useApiCallback'
import { IPasswordChangeResult } from '@norstella/nxp-sso-core/interfaces/sso-api';

const { userApi } = api

export type UsePasswordChange = [
    callApi: () => Promise<void>,
    data?: IPasswordChangeResult,
    isLoading? : boolean,
    error?: Error,
]

export const usePasswordChange = (): UsePasswordChange => {
  const [callApi, data, isLoading, error] = useApiCallback<void, IPasswordChangeResult>()
  const callback = useCallback(async () => {
    const result = await callApi(userApi.passwordChange())
    if (!result || result.error) {
      return
    }

    window.location.href = result.data!.redirectUrl
  }, [callApi])

  return [callback, data, isLoading, error]
}
