import React, { ChangeEvent, FC, memo } from 'react'
import { InputLabel, OutlinedInput } from '@mui/material'
import styles from './Input.module.scss'

export interface IInputProps {
  id: string,
  value: string,
  disabled?: boolean,
  displayName?: string,
  required?: boolean,
  handleEdit?: (id: string, value: string) => void,
}

export const Input: FC<IInputProps> = memo(({
  id,
  displayName = '',
  required = false,
  disabled = false,
  value,
  handleEdit,
}) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.persist()
    const fieldName = event.target.id || event.target.name
    const fieldValue = event.target.value
    handleEdit?.(fieldName, fieldValue)
  }

  return <>
    <InputLabel htmlFor={id} className={styles.inputLabel} disabled={disabled} id={`${id}-label`}>
      {`${required ? '*' : ''}${displayName}`}
    </InputLabel>
    <OutlinedInput
      className={styles.input}
      id={id}
      disabled={disabled}
      name={id}
      fullWidth={true}
      onChange={onChange}
      value={value}
    />
  </>
})
