import { GetTokenSilentlyOptions, useAuth0 } from '@auth0/auth0-react'
import { useCallback, useEffect, useState } from 'react'

export type UseAccessToken = [
  accessToken: string,
  canCallApi: boolean,
  getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string>,
]

export const useAccessToken = (options?: GetTokenSilentlyOptions): UseAccessToken => {
  const [accessToken, setAccessToken] = useState('')
  const { getAccessTokenSilently, isAuthenticated } = useAuth0()

  const getAccessToken = useCallback(async (options?: GetTokenSilentlyOptions) => {
    let token = ''
    if (isAuthenticated) {
      token = await getAccessTokenSilently(options)
    }
    setAccessToken(token)
    return token
  }, [getAccessTokenSilently, isAuthenticated])
  const canCallApi = accessToken !== '' && isAuthenticated

  useEffect(() => {
    (async () => await getAccessToken(options))()
  }, [getAccessToken, options])

  return [accessToken, canCallApi, getAccessToken]
}
