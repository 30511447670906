import { FC, Fragment } from 'react'

import { Divider, Typography } from '@mui/material'
import styles from '@norstella/nxp-sso-web/components/views/ErrorPageView.module.scss'
import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard';
import { IHeaderElement, IFooterElement } from '@norstella/nxp-sso-web/utils/Forms';

export interface IErrorPageView {
  error?: string,
  errorDescription?: string,
  header: IHeaderElement,
  footer?: IFooterElement,
}

export const ErrorPageView: FC<IErrorPageView> = ({
  error,
  errorDescription,
  header,
  footer,
}) => {
  const footerEl = footer && footer.text !== '' ?
    <Fragment>
      <Divider></Divider>
      <Typography component="p">
        {footer && footer.text}
        <a
          href={footer?.linkUrl}
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          {footer && footer.linkText}
        </a>
      </Typography>
    </Fragment>
    : null

  return <ApplicationCard className={styles.errorCard} header={header} >
    {
      error &&
      <Typography className={styles.errorName} component="p">
        {error}
      </Typography>
    }
    {
      errorDescription &&
      <Typography className={styles.errorDesc} component="p">
        {errorDescription}
      </Typography>
    }
    {footerEl}
  </ApplicationCard>
}
