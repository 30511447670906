import { useMemo } from 'react'
import { useAccessToken } from '@norstella/nxp-sso-web/hooks/useAccessToken'
import jwt_decode from 'jwt-decode'

export const useAvailableTokenScopes = (): string[] => {
  const [accessToken] = useAccessToken()

  return useMemo(() => {
    if (accessToken) {
      const decodedToken = jwt_decode<{scope: string}>(accessToken)
      const scope = decodedToken.scope || ''
      return scope.split(' ').filter(s => s)
    }
    return []
  }, [accessToken])
}
