import React, { FC, forwardRef } from 'react'
import { Alert, Typography } from '@mui/material'

import { IApplicationMessage } from '@norstella/nxp-sso-web/services/Interfaces/IApplicationMessage'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'

import styles from '@norstella/nxp-sso-web/components/ApplicationAlert.module.scss'

export interface IApplicationAlertProps {
  className?: string,
  message?: IApplicationMessage,
  resetMessage?: () => void,
  isCloseActionAvailable?: boolean,
}

export const ApplicationAlert: FC<IApplicationAlertProps> = forwardRef<HTMLDivElement, IApplicationAlertProps>(
  (
    {
      message,
      resetMessage,
      className,
    }, ref,
  ) => {
    const { message: contextMessage, resetMessage: contextResetMessage } = useAppContext()
    const alertMessage = message || contextMessage
    const alertResetMessage = resetMessage || contextResetMessage
    return <>
      {
        alertMessage &&
        <Alert ref={ref}
               className={`${styles.applicationError} ${className || ''}`}
               severity={alertMessage?.severity}
               onClose={alertResetMessage}>
          <Typography variant="body1" component="div">{alertMessage?.header}</Typography>
          <Typography variant="body2" component="div">{alertMessage?.description}</Typography>
        </Alert>
      }
    </>
  },
)
