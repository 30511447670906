import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

const getEntitlementProviderIds = (value: string | null): string[] => {
  if (!value) {
    return []
  }

  return value.split(',').map(id => id.trim())
}

export const useNewEntitlementProviderIds = (): string[] => {
  const [searchParams] = useSearchParams()
  const newEntitlementProviderIdsRaw = searchParams.get('entitlementProviderIds');

  return useMemo(() => getEntitlementProviderIds(newEntitlementProviderIdsRaw), [newEntitlementProviderIdsRaw])
}
