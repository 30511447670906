import { useCallback, useState } from 'react'

import { IApiCallResult } from '@norstella/nxp-sso-web/api'
import { useAccessToken } from '@norstella/nxp-sso-web//hooks/useAccessToken'

export interface IApiCallbackState<TResponseData> {
  apiCallResult?: IApiCallResult<TResponseData>,
  isStarted: boolean,
}

export type UseApiCallback<TResponseData> = [
  callApi: (executor: (token: string) => Promise<IApiCallResult<TResponseData>>) => Promise<IApiCallResult<TResponseData> | undefined>,
  data?: TResponseData,
  isStarted?: boolean,
  error?: Error,
]

export const useApiCallback = <TRequest, TResponseData = TRequest>(): UseApiCallback<TResponseData> => {
  const [{ apiCallResult, isStarted }, setApiCallResult] = useState({} as IApiCallbackState<TResponseData>)
  const [accessToken, canCallApi] = useAccessToken()

  const call = useCallback(async (executor: (token: string) => Promise<IApiCallResult<TResponseData>>) => {
    if (canCallApi) {
      setApiCallResult({ isStarted: true })
      const result = await executor(accessToken)
      setApiCallResult({
        apiCallResult: result,
        isStarted: false,
      })
      return result
    }
    return undefined
  }, [canCallApi, accessToken])

  return [call, apiCallResult?.data, isStarted, apiCallResult?.error]
}
