import axios, { AxiosError } from 'axios'
import { IApplicationMessage } from '@norstella/nxp-sso-web/services/Interfaces/IApplicationMessage'
import {
  MessageFactory,
  ErrorMessageHandler, ErrorMessageHandlers,
} from '@norstella/nxp-sso-web/services/ErrorMessageHandlers'

export class ErrorMessagesRegistry {
  private readonly registry = new Map<string, MessageFactory>()

  register(handler: ErrorMessageHandler) {
    this.registry.set(...handler)
    return this
  }

  registerMany(handlers: ErrorMessageHandler[]) {
    handlers.forEach(handler => this.register(handler))
    return this
  }

  handle(error: Error): IApplicationMessage | Error {
    const errorToHandle = this.getErrorToHandle(error)
    const handler = this.registry.get(errorToHandle.name)
    return handler ? handler(errorToHandle) : errorToHandle
  }

  private getErrorToHandle(error: Error): Error {
    if (axios.isAxiosError(error) && (error as AxiosError).response && (error.response?.data as Error).name) {
      return error.response?.data as Error
    }
    return error
  }
}

export const ApplicationErrorsRegistry = new ErrorMessagesRegistry().registerMany(ErrorMessageHandlers)
