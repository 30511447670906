import { HttpStatusCode } from 'axios'

export interface IApplicationError<TErrorData> {
  name: string,
  displayName: string,
  data?: TErrorData,
  message: string,
  stack?: string,
  httpStatusCode?: HttpStatusCode,
  payload?: unknown,
  cause?: unknown,
}

export class ApplicationError<TErrorData> extends Error implements IApplicationError<TErrorData> {
  public readonly data?: TErrorData
  public readonly displayName: string
  public readonly message: string
  public readonly httpStatusCode: HttpStatusCode
  public readonly payload?: unknown
  public readonly cause?: unknown

  constructor(errorDetails: Partial<IApplicationError<TErrorData>> = {}) {
    super()
    const prototype = Object.getPrototypeOf(this) as ApplicationError<TErrorData>
    this.name = prototype.constructor.name
    this.data = errorDetails.data
    this.message = errorDetails.message as string
    this.displayName = errorDetails.displayName || this.name
    this.httpStatusCode = errorDetails.httpStatusCode || HttpStatusCode.InternalServerError
    this.payload = errorDetails.payload
    this.cause = errorDetails.cause
  }
}
