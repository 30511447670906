import { FormType } from '@norstella/nxp-sso-web/utils/Forms'
import { useNavigateToSource } from '@norstella/nxp-sso-web/hooks/useNavigateToSource'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { ProfilePageView } from '@norstella/nxp-sso-web/components/views/ProfilePageView'
import { UserType } from '@norstella/nxp-sso-core/interfaces/sso-api'

export const Profile = () => {
  const navigateToSource = useNavigateToSource()
  const {
    profileData,
    apiHooks,
  } = useAppContext()
  const [updateProfile, , isUpdateInProgress] = apiHooks.user.useUpdateProfile()

  const [passwordChange, , isPasswordChangeInProgress] = apiHooks.user.usePasswordChange()

  const isUpdating = isUpdateInProgress || isPasswordChangeInProgress

  const currentFormType = profileData.userType === UserType.trial
    ? FormType.trialProfile
    : FormType.profile

  return <ProfilePageView
    formType={currentFormType}
    submitButtonText="Save changes"
    isUpdateInProgress={isUpdating}
    onSubmitHandler={updateProfile}
    onNavigateBackHandler={navigateToSource}
    onPasswordChangeHandler={passwordChange}
  />
}
