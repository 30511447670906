import * as React from 'react'
import Typography from '@mui/material/Typography'

import styles from '@norstella/nxp-sso-web/views/NotFound.module.scss'

export const NotFound = () => (
  <div className="home">
    <Typography variant="h4" className={styles.message}>
      Requested page not found.
      </Typography>
  </div>
)
