import { FC } from 'react'
import { useLocation } from 'react-router-dom';
import { ErrorPageView } from '@norstella/nxp-sso-web/components/views/ErrorPageView'
import { FormType } from '@norstella/nxp-sso-web/utils/Forms'
import { useBranding } from '../hooks/useBranding';

export const Error: FC = () => {
  const urlParams = new URLSearchParams(useLocation().search)
  const [forms] = useBranding()

  const error = urlParams.get('error') || undefined
  const errorDescription = urlParams.get('error_description') || undefined

  const { header, footer } = forms[FormType.error]

  return <ErrorPageView
    error={error}
    errorDescription={errorDescription}
    header={header}
    footer={footer}
  />
}
