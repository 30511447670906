import { useCallback, useState } from 'react'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'

export type UseForm<TFormData > = [
  formData: TFormData,
  handleFormEdit: (fieldName: string, fieldValue: string) => void,
]

export const useForm = <TFormData>(initialFormData: TFormData): UseForm<TFormData> => {
  const [formData, setFormData] = useState<TFormData>(initialFormData)
  const { setFormUpdated } = useAppContext()

  const handleFormEdit = useCallback((fieldName: string, fieldValue: string) => {
    setFormUpdated(true)
    setFormData((prevState: TFormData) => {
      return {
        ...prevState,
        [fieldName]: fieldValue,
      }
    })
  }, [setFormUpdated])

  return [formData, handleFormEdit]
}
