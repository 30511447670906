import { AxiosRequestConfig } from 'axios'
import userApi from '@norstella/nxp-sso-web/api/userApi'
import { HttpMethod } from '@norstella/nxp-sso-web/api/HttpMethod'
import { getHttpClient } from '@norstella/nxp-sso-web/components/AxiosInterceptor'

export interface IApiCallResult<TResponseData> {
  data?: TResponseData,
  error?: Error,
}

export interface IApiEndpoint {
  path: string,
  method: HttpMethod,
}

const createOptions = <TRequest>(endpoint: IApiEndpoint, data: TRequest, token: string): AxiosRequestConfig => {
  return {
    url: endpoint.path,
    method: endpoint.method,
    headers: { authorization: `Bearer ${token}`, 'content-type': 'application/json' },
    data,
  }
}

export const callApi = async <TRequest, TResponseBody = TRequest>(endpoint: IApiEndpoint, data: TRequest, token: string) => {
  const result: IApiCallResult<TResponseBody> = {}
  try {
    const options = createOptions(endpoint, data, token)
    const response = await getHttpClient().request<TResponseBody>(options)
    result.data = response.data
  } catch (error) {
    result.error = error as Error
  }

  return result
}

const api = {
  userApi: {
    ...userApi,
  },
}

export default api
