import { useUpdateProfile } from '@norstella/nxp-sso-web/hooks/useUpdateProfile'
import { useCompleteSignup } from '@norstella/nxp-sso-web/hooks/useCompleteSignup'
import { useVerifyEmail } from '@norstella/nxp-sso-web/hooks/useVerifyEmail'
import { usePasswordChange } from '@norstella/nxp-sso-web/hooks/usePasswordChange'

export const apiHooks = {
  user: {
    useUpdateProfile,
    useCompleteSignup,
    useVerifyEmail,
    usePasswordChange,
  },
}
