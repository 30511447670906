import { useCallback } from 'react'
import api from '@norstella/nxp-sso-web/api'
import { useApiCallback } from '@norstella/nxp-sso-web/hooks/useApiCallback'
import { useSignupRedirectState } from '@norstella/nxp-sso-web/hooks/useSignupRedirectState';
const { userApi } = api

export type UseVerifyEmail = [
  callback: () => Promise<void>,
]
export const useVerifyEmail = (): UseVerifyEmail => {
  const [signupState] = useSignupRedirectState()

  const [callApi] = useApiCallback<void, void>()
  const callback = useCallback(async () => {
    await callApi(userApi.verifyEmail({ appClientId: signupState?.data?.client_id }))
  }, [callApi])

  return [callback]
}
