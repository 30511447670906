import React, { FC, PropsWithChildren } from 'react'
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material'
import { IHeaderElement } from '@norstella/nxp-sso-web/utils/Forms'
import { ApplicationAlert } from '@norstella/nxp-sso-web/components/ApplicationAlert'

import styles from '@norstella/nxp-sso-web/components/ApplicationCard.module.scss'
import { useBranding } from '@norstella/nxp-sso-web/hooks/useBranding';

export interface IApplicationCardProps {
  header: IHeaderElement,
  description?: IHeaderElement,
  className?: string,
  subHeader?: JSX.Element,
}

const ApplicationCard: FC<PropsWithChildren<IApplicationCardProps>> = ({
  header,
  description,
  children,
  className,
  subHeader,
}) => {
  const headerCLasses = header.isGeneralBrandingUsed ?
    `logo-container ${styles.cardHeaderContainer} ${styles.generalBrandingHeader}` :
    `logo-container ${styles.cardHeaderContainer}`

  const [forms, branding] = useBranding()
  const themeClass = `theme-${branding.themeId}`

  return <Grid
    className={`${styles.cardRoot} ${className || ''}`}
    container={true}
    justifyContent="center"
    alignItems="center"
    direction="column"
    spacing={5}>
    <Grid item className={themeClass}>
      <Grid container={true} direction="column" alignItems="center" justifyContent="center">
        <Card className={styles.card}>
          <div className={headerCLasses} style={{ backgroundColor: header.bgColor }}>

            <CardMedia className={styles.cardHeaderMedia} component="img" image={header.image} />
          </div>
          <CardContent>
            {subHeader && subHeader}
            <ApplicationAlert className={styles.cardAlert} />
            <Typography variant="h1" component="h1">
              {header.text}
            </Typography>
            {
              description &&
              <Typography variant={description.variant} component="p">
                {description.text}
              </Typography>
            }
            {children}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </Grid>
}

export default ApplicationCard
