import { useState } from 'react'
import { ApplicationError } from '@norstella/nxp-sso-core/errors/ApplicationError'
import { IApplicationMessage } from '@norstella/nxp-sso-web/services/Interfaces/IApplicationMessage'

export type UseApplicationMessage = [
  message: IApplicationMessage|undefined,
  setMessage: (value?: IApplicationMessage|Error) => void,
  resetMessage: () => void,
]

const createMessage = (value?: IApplicationMessage|Error): IApplicationMessage|undefined => {
  if (value instanceof ApplicationError) {
    return {
      header: value.displayName,
      description: value.message,
      severity: 'error',
    }
  }

  if (value instanceof Error) {
    return {
      header: value.name,
      description: value.message,
      severity: 'error',
    }
  }

  return value as IApplicationMessage
}

export const useApplicationMessage = (initialMessage?: IApplicationMessage|Error): UseApplicationMessage => {
  const [message, setMessageState] = useState(createMessage(initialMessage))
  const resetMessage = () => setMessageState(undefined)
  const setMessage = (value?: IApplicationMessage|Error) => {
    setMessageState(createMessage(value))
  }

  return [message, setMessage, resetMessage]
}
