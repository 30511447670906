import { ISelectProps, Select } from '@norstella/nxp-sso-web/components/userProfile/Select'
import { IInputProps, Input } from '@norstella/nxp-sso-web/components/userProfile/Input'
import { IUserMetadata } from '@norstella/nxp-sso-core/interfaces/auth0'
import { FormType } from '@norstella/nxp-sso-web/utils/Forms'
import {
  IPhoneNumberInputProps,
  PhoneNumberInput,
} from '@norstella/nxp-sso-web/components/userProfile/PhoneNumberInput'
import { ARIARole } from 'aria-query'

export type ProfileData = { email?: string } & IUserMetadata
export type ProfileDataItemKey = keyof ProfileData
export type ProfileDataItemValue = ProfileData[ProfileDataItemKey]

export const enum FormFieldType {
  text = 'textbox',
  select = 'select',
  phone = 'phone'
}

export interface IProfileFormField {
  id: ProfileDataItemKey,
  displayName?: string,
  required?: boolean,
  type: FormFieldType,
  role: ARIARole | string,
  visibleOn?: FormType[],
  requiredOn?: FormType[],
  disabled?: boolean,
  values?: Array<string> | [],
}

const formFieldTypesMap = {
  [FormFieldType.text]: Input,
  [FormFieldType.select]: Select,
  [FormFieldType.phone]: PhoneNumberInput,
}

export type FormFieldProps = ISelectProps | IInputProps | IPhoneNumberInputProps

export const getFormField = (type: FormFieldType) => {
  const component = formFieldTypesMap[type]

  if (!component) {
    throw new Error(`Form field type ${type} is not supported`)
  }
  return component
}
