import { withAuthenticationRequired } from '@auth0/auth0-react'
import React, { ComponentType, FC } from 'react'

interface IAuthenticationGuardProps {
  component: ComponentType,
}

export const AuthenticationGuard: FC<IAuthenticationGuardProps> = ({
  component,
}) => {
  const Component = withAuthenticationRequired(component, {
    returnTo: `${window.location.pathname}${window.location.search}`,
  })
  return <Component/>
}
