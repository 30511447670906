import React, { FC, PropsWithChildren, useState } from 'react'
import { isEmpty } from 'lodash'

import { IProfileData } from '@norstella/nxp-sso-core/interfaces/sso-api'
import { FormType } from '@norstella/nxp-sso-web/utils/Forms'

import { useForm } from '@norstella/nxp-sso-web/hooks/useForm'
import { useProfileFormFields } from '@norstella/nxp-sso-web/hooks/useProfileFormFields'

import ApplicationCard from '@norstella/nxp-sso-web/components/ApplicationCard'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { FormFieldProps, getFormField } from '@norstella/nxp-sso-web/components/userProfile/formFieldFactory'

import 'highlight.js/scss/solarized-dark.scss'
import styles from '@norstella/nxp-sso-web/components/userProfile/UserProfile.module.scss'
import { useBranding } from '@norstella/nxp-sso-web/hooks/useBranding'

export interface IUserProfileProps {
  formType: FormType,
  onSubmitHandler: (profileData: IProfileData) => Promise<void>,
  subHeader?: JSX.Element,
}

export const UserProfile: FC<PropsWithChildren<IUserProfileProps>> = ({
  formType,
  children,
  onSubmitHandler,
  subHeader,
}) => {
  const { profileData, setFormUpdated } = useAppContext()
  const [formData, handleEdit] = useForm(profileData)
  const [forms] = useBranding()

  const profileFormFields = useProfileFormFields(formType)
  const [validatingMessage, setValidatingMessage] = useState('')
  const { header, description } = forms[formType]

  const renderForm = () => profileFormFields.allFields
    .map(formField => {
      const FormField = getFormField(formField.type)
      const value = formData[formField.id]
      const props = { ...formField, value, handleEdit } as FormFieldProps
      return <FormField key={formField.id} {...props} />
    })

  const isMetadataValid = () => {
    const invalidFields = profileFormFields.requiredFields
      .filter(f => isEmpty(formData[f.id]))
      .map(f => f.displayName)

    if (invalidFields.length > 0) {
      setValidatingMessage(`Some required fields are empty: ${invalidFields.join(', ')}`)
      return false
    }

    setValidatingMessage('')
    return true
  }

  const handleFormSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()
    if (isMetadataValid()) {
      setFormUpdated(false)
      onSubmitHandler(formData)
    }
  }

  return <ApplicationCard header={header} description={description} className={styles.userProfileCard} subHeader={subHeader}>
    <form onSubmit={handleFormSubmit} noValidate autoComplete="off">
      {renderForm()}
      <p data-testid="validating-message" className={styles.validationMessage}>{validatingMessage}</p>
      {children}
    </form>
  </ApplicationCard>
}
