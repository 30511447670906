import { Auth0ProviderOptions } from '@auth0/auth0-react'

interface IAppConfig {
  audience: string,
  domain: string,
  clientId: string,
  apiOrigin: string,
}

const tryGetValue = (name: string): string => {
  const value = process.env[name]
  if (value === undefined) {
    throw new Error(`configuration param ${name} is missed`)
  }
  return value
}

export const getAppConfig = (): IAppConfig => ({
  domain: tryGetValue('REACT_APP_AUTH0_DOMAIN'),
  clientId: tryGetValue('REACT_APP_AUTH0_CLIENT_ID'),
  apiOrigin: tryGetValue('REACT_APP_API_ORIGIN'),
  audience: tryGetValue('REACT_APP_AUTH0_AUDIENCE'),
})

export const getAuth0Config = (): Auth0ProviderOptions => {
  const appConfig = getAppConfig()
  return {
    domain: appConfig.domain,
    clientId: appConfig.clientId,
    authorizationParams: {
      scope: 'email profile nxp-sso:user nxp-sso:token-tester',
      audience: appConfig.audience,
      redirect_uri: `${window.location.origin}/callback`,
    },
  }
}
