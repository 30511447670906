import { useCallback } from 'react'

import api from '@norstella/nxp-sso-web/api'
import { useApiCallback } from '@norstella/nxp-sso-web/hooks/useApiCallback'
import { UpdateProfileData } from '@norstella/nxp-sso-web/api/userApi'
import { IProfileData } from '@norstella/nxp-sso-core/interfaces/sso-api'

const { userApi } = api

export type UseUpdateProfile = [
  callApi: (profileData: IProfileData) => Promise<void>,
  data?: void,
  isLoading? : boolean,
  error?: Error,
]

export const useUpdateProfile = (): UseUpdateProfile => {
  const [callApi, data, isLoading, error] = useApiCallback<UpdateProfileData, void>()
  const callback = useCallback(async (profileData: IProfileData) => {
    await callApi(userApi.updateProfile(profileData))
  }, [callApi])

  return [callback, data, isLoading, error]
}
