import { ApplicationError, IApplicationError } from '@norstella/nxp-sso-core/errors/ApplicationError'

export class SignupError<TErrorData> extends ApplicationError<TErrorData> {
  constructor(errorDetails?: Partial<IApplicationError<TErrorData>>) {
    super({
      displayName: 'System error',
      message: 'Sign up could not be completed. Try again later.',
      ...errorDetails,
    })
  }
}
