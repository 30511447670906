import { FC, PropsWithChildren } from 'react'
import { useAvailableTokenScopes } from '@norstella/nxp-sso-web/hooks/useAvailableTokenScopes'

export interface IAuthorizationGuardProps extends PropsWithChildren {
  scopes: string[],
}

export const AuthorizationGuard: FC<IAuthorizationGuardProps> = ({ scopes, children }) => {
  const availableScopes = useAvailableTokenScopes()

  return <>
    { scopes.some(s => availableScopes.includes(s)) && children }
  </>
}
