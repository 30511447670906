import { ApplicationError, IApplicationError } from '@norstella/nxp-sso-core/errors/ApplicationError'

export class EmailAlreadyVerifiedError<TErrorData> extends ApplicationError<TErrorData> {
  constructor(errorDetails: Partial<IApplicationError<TErrorData>> = {}) {
    super({
      message: 'Email is already verified',
      ...errorDetails,
    })
  }
}
