import { HttpStatusCode } from 'axios'
import { ApplicationError, IApplicationError } from '@norstella/nxp-sso-core/errors/ApplicationError'

export interface ISchemaValidationErrorData<TEntity> {
  recordHandle: string,
  entity?: TEntity,
}

export class SchemaValidationError<TErrorData> extends ApplicationError<TErrorData> {
  constructor(errorDetails?: Partial<IApplicationError<TErrorData>>) {
    super({
      displayName: 'Schema validation error',
      httpStatusCode: HttpStatusCode.UnprocessableEntity,
      ...errorDetails,
    })
  }
}
