import axios, { Axios, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { getAppConfig } from '@norstella/nxp-sso-web/config'
import { useAppContext } from '@norstella/nxp-sso-web/components/AppContextProvider'
import { ApplicationErrorsRegistry } from '@norstella/nxp-sso-web/services/ErrorMessagesRegistry'

let internalHttpClient: Axios

export const getHttpClient = () => {
  internalHttpClient = internalHttpClient || axios.create({
    baseURL: getAppConfig().apiOrigin,
  })

  return internalHttpClient
}

export const AxiosInterceptor: FC<PropsWithChildren> = ({ children }) => {
  const [isSet, setIsSet] = useState(false)
  const { setMessage, resetMessage } = useAppContext()
  useEffect(() => {
    const onRequest = (config: InternalAxiosRequestConfig) => {
      resetMessage()
      return config
    }
    const onRequestError = (error: Error) => {
      setMessage(error)
    }
    const onResponse = (response: AxiosResponse) => response
    const onResponseError = (error: Error) => {
      const message = ApplicationErrorsRegistry.handle(error)
      setMessage(message)
    }

    const requestInterceptor = getHttpClient().interceptors.request.use(onRequest, onRequestError)
    const responseInterceptor = getHttpClient().interceptors.response.use(onResponse, onResponseError)
    setIsSet(true)
    return () => {
      getHttpClient().interceptors.request.eject(requestInterceptor)
      getHttpClient().interceptors.response.eject(responseInterceptor)
    }
  }, [resetMessage, setMessage])

  return <>
    {isSet && children}
  </>
}
