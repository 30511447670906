import React, { createContext, FC, useContext, useState } from 'react'
import { LogoutOptions, useAuth0 } from '@auth0/auth0-react'
import { IProfileData } from '@norstella/nxp-sso-core/interfaces/sso-api'
import { IRegistrationStatus } from '@norstella/nxp-sso-web/services/RoutesService'
import { apiHooks } from '@norstella/nxp-sso-web/api/apiHooks'
import { useProfileData } from '@norstella/nxp-sso-web/hooks/useProfileData'
import { useRegistrationStatus } from '@norstella/nxp-sso-web/hooks/useRegistrationStatus'
import { User } from '@norstella/nxp-sso-web/utils/User'
import { useApplicationMessage } from '@norstella/nxp-sso-web/hooks/useApplicationMessage'
import { IApplicationMessage } from '@norstella/nxp-sso-web/services/Interfaces/IApplicationMessage'
import { useLocation } from 'react-router-dom';
import { useFeatureFlags } from '@norstella/nxp-sso-web/hooks/useFeatureFlags';

export interface IAppData {
  user?: User,
  profileData: IProfileData,
  isLoading: boolean,
  logout: (options?: LogoutOptions) => Promise<void>,
  isUserLoggedIn: boolean,
  message?: IApplicationMessage,
  setMessage: (error?: IApplicationMessage|Error) => void,
  resetMessage: () => void,
  registrationStatus: IRegistrationStatus,
  formUpdated: boolean,
  setFormUpdated: (formUpdated: boolean) => void,
  apiHooks: typeof apiHooks,
  shouldContinueLogin?: boolean,
  setShouldContinueLogin?: (value: boolean) => void,
}

export const AppContext = createContext({} as IAppData)

export interface IAppContextProviderPropsType {
  children: React.JSX.Element,
}

export const AppContextProvider: FC<IAppContextProviderPropsType> = ({ children }): JSX.Element => {
  const { user, isAuthenticated, error: auth0Error, isLoading, logout } = useAuth0<User>()
  const profileData = useProfileData()
  const [shouldContinueLogin, setShouldContinueLogin] = useState(false)
  const registrationStatus = useRegistrationStatus(shouldContinueLogin)
  const [formUpdated, setFormUpdated] = useState<boolean>(false)
  const [message, setMessage, resetMessage] = useApplicationMessage()
  const url = useLocation()
  const { isDebugLevelEnabled } = useFeatureFlags()

  if (auth0Error && url?.pathname !== '/error') {
    setMessage(auth0Error)
  }

  const isUserLoggedIn = isAuthenticated && !isLoading

  if (isDebugLevelEnabled) {
    console.log('AppContextProvider', JSON.stringify({
      registrationStatus,
      shouldContinueLogin,
      formUpdated,
      isLoading,
      profileData,
    }, null, 2));
  }

  return <AppContext.Provider value={{
    user,
    profileData,
    logout,
    isUserLoggedIn,
    isLoading,
    registrationStatus,
    message,
    resetMessage,
    setMessage,
    formUpdated,
    setFormUpdated,
    apiHooks,
    shouldContinueLogin,
    setShouldContinueLogin,
  }}>
    {children}
  </AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)
